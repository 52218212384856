import { useState, useRef, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import useWindowDimenions from "../Hooks/useWindowDimensions";
import '../App.scss';

const Start = props => {

  const [isLoading, setIsLoading]         = useState(true);
  const [videoThumbnailImg, setVideoThumbnailImg] = useState(VideoThumbnail);

  const { windowWidth } = useWindowDimenions();
  console.log(windowWidth);

  // useEffect(() => {
  //   if(windowWidth < 831) setVideoThumbnailImg(VideoThumbnailMobile);
  //   else setVideoThumbnailImg(VideoThumbnail);
  // }, [windowWidth])

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  useEffect(() => {
		// document.body.style.backgroundColor = "#EEEEEE";
	},[]);

  console.log("searchParams", searchParams.get("leader"));
  console.log("searchParams", searchParams.get("user"));
  const assessmentLink = () => {
    // if(!searchParams) return "/assessment";
    // else return "/assessment?" + searchParams;
    return "/assessment?" + searchParams;
  }

  const preAssessmentLink = () => {
    if(searchParams == "") return "/before-you-start";
    else return "/before-you-start?" + searchParams;
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <>
          <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
          <div className="caption">
            <p className="name">Ross Miller</p>
            <p>President</p>
            <p>TouchPoint Software</p>
          </div>
        </>
      }
    }
  }
  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";

  return (
    <div className="container start">
      <div className="video">
        <video ref={vidRef}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/bleat-touchpoint.mp4"
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {/* {showPlayButton()} */}
      </div>
      <div className="intro">
        <h1>Are you and your staff aligned about your ChMS?</h1>
        {/* <h2>
        1. Take this 3-minute ChMS test<br/>
        2. Invite your team to take it too<br/>
        3. See all results on 1 dashboard<br/>
        4. Align your team around action<br/>
        </h2> */}
        <ol className="intro-points-list">
        {searchParams.get("leader") ? 
          <>
            <li>Take this 4-minute ChMS test</li>
            <li>View your results instantly</li>
            <li>Begin to align around action with your team</li>
          </> : <>
            <li>Take this 5-minute ChMS test</li>
            <li>Invite your team to take it too</li>
            <li>See all results on 1 dashboard</li>
            <li>Align your team around action</li>
          </>}
        </ol>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="estimated time" /> <span>{searchParams.get("leader") ? "4 min" : "5 min"}</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;
